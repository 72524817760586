import { Dispatch, SetStateAction, useState } from 'react';
import constate from 'constate';

interface UseAppStateValues {
  currentBestReviewPage: number;
  setCurrentBestReviewPage: Dispatch<SetStateAction<number>>;
}
function useAppState(): UseAppStateValues {
  const [currentBestReviewPage, setCurrentBestReviewPage] = useState<number>(0);

  return { currentBestReviewPage, setCurrentBestReviewPage };
}

export const [AppStateProvider, useAppStateContext] = constate(useAppState);
