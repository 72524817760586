import React, { useEffect } from 'react';
import 'styles/index.css';
import Head from 'next/head';
// @ts-ignore
import * as Sentry from '@sentry/nextjs';
import { ApolloProvider } from '@apollo/client';
import nProgress from 'nprogress';
import { Router } from 'next/router';
import GlobalProvider from 'contexts';
import { useApollo } from 'graphql/apollo';
import ResponsiveTemplate from 'components/Layout/ResponsiveTemplate';

Router.events.on('routeChangeStart', nProgress.start);
Router.events.on('routeChangeError', nProgress.done);
Router.events.on('routeChangeComplete', nProgress.done);

export default function App(props: any) {
  const { Component, pageProps, router } = props;
  const apolloClient = useApollo(pageProps);

  useEffect(() => {
    const jssStyles: any = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }, []);

  return (
    <>
      <Sentry.ErrorBoundary>
        <Head>
          <title>병원갈땐, 굿닥 | goodoc</title>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
          <meta property="og:title" content="병원 이벤트 모아보기 | 병원약국 검색어플, 굿닥" />
          <meta name="twitter:title" content="병원 이벤트 모아보기 | 병원약국 검색어플, 굿닥" />
          <meta property="og:image" content="https://clinicmarket.goodoc.co.kr/images/og_image.png" />
          <meta name="twitter:image" content="https://clinicmarket.goodoc.co.kr/images/og_image.png" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:site_name" content="병원약국 검색어플, 굿닥" />
          <meta property="og:type" content="website" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          <link rel="shortcut icon" href="/images/favicon.ico" />
          <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css"
            type="text/css"
          />
          <script defer src="//unpkg.com/smoothscroll-polyfill@0.4.4/dist/smoothscroll.min.js" />
          <script
          // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-KD57GMX');`,
          }}
          />
          <script
          // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
            __html: `
        window.fbAsyncInit = function() {
        FB.init({
            appId: '785281221625698',
            autoLogAppEvents: true,
            xfbml: true,
            version: 'v16.0'
        });
        };`,
          }}
          />
          <meta name="facebook-domain-verification" content="9h14plk97spac402rqqgomiqchoxvc" />
          <script async defer crossOrigin="anonymous" src="https://connect.facebook.net/ko_KR/sdk.js" />
          <script src="https://developers.kakao.com/sdk/js/kakao.js" />
          <script dangerouslySetInnerHTML={{ __html: `Kakao.init('${process.env.KAKAO_APP_KEY}');` }} />
          <script
            type="text/javascript"
            defer={false}
            src="https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=r464xknos5&submodules=geocoder"
          />
        </Head>

        <ApolloProvider client={apolloClient}>
          <GlobalProvider>
            <ResponsiveTemplate>
              <Component {...pageProps} key={router.route} />
            </ResponsiveTemplate>
          </GlobalProvider>
        </ApolloProvider>
      </Sentry.ErrorBoundary>
    </>
  );
}
