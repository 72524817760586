import { css } from '@emotion/css';
import Color from 'styles/colors';

// Snackbar //////////////////////////////
export const containerAnchorOriginBottomCenter = css`
  .notistack-CollapseWrapper {
    padding: 0;
  }
  max-width: calc(100% - 40px);
  bottom: 20px;
`;

export const anchorOriginBottomCenter = css`
  .notistack-MuiContent {
    min-height: 53px;
    box-shadow: none;
    border-radius: 8px;
  }
  .notistack-MuiContent-error {
    background-color: ${Color.Red[60]};
  }
`;
// Snackbar //////////////////////////////
