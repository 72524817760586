export enum FlexDirection {
  Row = 'row',
  RowReverse = 'row-reverse',
  Column = 'column',
  ColumnReverse = 'column-reverse',
}

/**
 * TODO: 디자인 가이드에 따라 컬러값 추가
 */
export enum Color {
  Black = '#000000',
  Black10 = 'rgba(0, 0, 0, 0.1)',
  Black35 = 'rgba(0, 0, 0, 0.35)',
  Black50 = 'rgba(0, 0, 0, 0.5)',
  Black80 = 'rgba(0, 0, 0, 0.8)',

  Red = '#ef4e4a',
  Red10 = '#FFF8F8',
  Red20 = '#FFEBEB',
  Red30 = '#FFE0E0',
  Red40 = '#FFB8B8',
  Red50 = '#FF7575',
  Red60 = '#FF4D4D',
  Red70 = '#F03838',
  Red80 = '#E01F1F',
  Red90 = '#D01B1B',
  Red100 = '#B62020',
  Yellow = 'rgb(254, 213, 69)',

  White = '#ffffff',
  WhiteTwo = '#f4f4f4',

  LineBg = '#e9e9e9',
  BlueGrey = '#f8fbff',
  PaleGrey = '#fafafc',
  Shadow = '#363839',

  Grey = '#F9F9FB',
  Gray20 = '#F2F4F7',
  Gray30 = '#E3E6ED',
  Gray40 = '#CACED8',
  Gray50 = '#A8AEBD',
  Gray60 = '#808799',
  Gray70 = '#5D6474',
  Gray80 = '#434956',
  Gray90 = '#31353F',
  Gray100 = '#16181D',

  Blue10 = '#F6FBFF',
  Blue20 = '#E6F3FF',
  Blue30 = '#CEE8FF',
  Blue40 = '#8ECFFF',
  Blue50 = '#4FAFFF',
  Blue60 = '#0073FA',
  Blue70 = '#057AED',
  Blue80 = '#1068D9',
  Blue90 = '#0F55C7',
  Blue100 = '#1141A8',

  Green10 = '#E9FBF3',
  Green20 = '#DCF9ED',
  Green30 = '#C7F5E1',
  Green40 = '#A1EFCD',
  Green50 = '#6DE1AF',
  Green60 = '#41D293',
  Green70 = '#2AC180',
  Green80 = '#18AC6C',
  Green90 = '#0B9C67',
  Green100 = '#008A55',

  IndicatorBlue = '#ebf3f9',
  PurpleBrown = '#381e1f',
  GreyishTeal = '#54bca4',

  DodgerBlue = '#3c82ff',
  Primary = '#0073FA',
}

export enum FontWeight {
  Regular = 400,
  Medium = 500,
  SemiBold = 600,
  Bold = 'bold',
}

export enum LineSpace {
  S0 = 0,
  S30 = 0.3,
  S40 = 0.4,
}

export enum FontSize {
  P10 = 10,
  P12 = 12,
  P14 = 14,
  P16 = 16,
  P18 = 18,
  P20 = 20,
  P22 = 22,
  P24 = 24,
  P26 = 26,
  P40 = 40,
}
