import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { koKR } from '@mui/x-date-pickers/locales';
import React from 'react';
import { SnackbarProvider } from 'notistack';
import { Color } from 'styles/guideline';
import { DialogProvider } from 'contexts/DialogContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LoaderProvider } from './LoaderContext';
import { AnalyticsGTMProvider } from './AnalyticsGTMContext';
import { AnalyticsProvider } from './AnalyticsContext';
import { FunnelProvider } from './FunnelContext';
import { HeaderProvider } from './HeaderContext';
import { UserProvider } from './UserContext';
import { CategoryProvider } from './CategoryContext';
import { LocationProvider } from './LocationContext';
import { EventCartProvider } from './EventCartContext';
import { ToastProvider } from './ToastContext';
import { AppStateProvider } from './AppStateContext';
import * as styles from './styles';

export type GlobalProviderProps = {
  children: React.ReactNode;
};

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#000000',
    },
    primary: {
      light: Color.Primary,
      dark: Color.Primary,
      main: Color.Primary,
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 8,
      },
    },
  },
});

const xTheme = createTheme(
  {
    palette: { ...theme.palette },
  },
  koKR
);

export default function GlobalProvider(props: GlobalProviderProps) {
  const { children } = props;

  const { containerAnchorOriginBottomCenter, anchorOriginBottomCenter } = styles;

  return (
    <HeaderProvider>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={xTheme}>
          <SnackbarProvider
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            maxSnack={1}
            classes={{ containerAnchorOriginBottomCenter, anchorOriginBottomCenter }}
          >
            <AppStateProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ToastProvider>
                  <DialogProvider>
                    <UserProvider>
                      <FunnelProvider>
                        <AnalyticsGTMProvider>
                          <AnalyticsProvider>
                            <CategoryProvider>
                              <EventCartProvider>
                                <LoaderProvider>
                                  <LocationProvider>{children}</LocationProvider>
                                </LoaderProvider>
                              </EventCartProvider>
                            </CategoryProvider>
                          </AnalyticsProvider>
                        </AnalyticsGTMProvider>
                      </FunnelProvider>
                    </UserProvider>
                  </DialogProvider>
                </ToastProvider>
              </LocalizationProvider>
            </AppStateProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </HeaderProvider>
  );
}
